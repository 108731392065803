<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        v-bind="attrs"
        v-on="on"
        outlined
        color="grey"
      >
        Nova mensagem
        <v-icon
            class="px-2"
        >mdi-email</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-10">
      <v-form ref="form">
        <v-card-title class="title">Nova mensagem</v-card-title>
        <v-card-text>
            <v-text-field 
              v-model="mensagem.assunto"
              label="Assunto" 
              outlined
            ></v-text-field>
            <v-autocomplete
              v-model="mensagem.destinatario"
              :items="usuarios" 
              return-object
              label="Destinatários" 
              outlined
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="mensagem.destinatarios"
              :items="usuarios" 
              return-object
              multiple
              label="Destinatários" 
              outlined></v-autocomplete>
            <v-textarea 
              v-model="mensagem.mensagem"
              label="Mensagem" 
              outlined
            ></v-textarea>
            
            <!---
            <v-file-input></v-file-input>
            -->
           
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            outlined
            color="grey"
            @click="enviar"
          >
            enviar
            <v-icon
              class="px-2"
            >mdi-send</v-icon>
          </v-btn>    
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog> 
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:"Novo",
  data:()=>({
    dialog:false,
    mensagem:{
      assunto: "teste",
      destinatario: {id:2},
      destinatarios:[{id:2,text:"Moura Junior",username:"831.417.153-00",
        email:"mourasistema@gmail.com",
        update_url:"/contas/usuarios/2/",
        first_name:"Moura",
        last_name:"Junior",
        is_active:true,
        avatar:null,
        papel:[{id:1,value:0,text:"Colaborador"}],papel_default:1,trocar_senha:true},
        {id:3,
        text:"Andreza Borges",username:"831.417.153-02",email:"andreza.borges@focoinfo.com.br",update_url:"/contas/usuarios/3/",first_name:"Andreza",last_name:"Borges",is_active:true,avatar:null,papel:[],papel_default:1,trocar_senha:true}
      ],
      mensagem: "teste de envio"
    },
  }),
  computed:{
    ...mapGetters(['usuarios','loading','lista_sugestoes'])
  },
  methods:{
    onUpdate (text) {
      this.text = text
    },
    enviar(){
      this.$store.dispatch('usuarios',{
        url:'mensagens/mensagens/',
        method:'post',
        data: this.mensagem,
        intancia:'lista_2'
      })
    }
  },
  mounted(){
    this.$store.dispatch('btnLoading',false)
    this.$store.dispatch('usuarios',{
      url:'contas/usuarios/',
      method:'get',
    })
  }
}
</script>